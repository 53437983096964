import React from 'react';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './SearchInputForm.module.scss';
import Icon from "../Icon";

const SearchInputForm = ({value, onChange, onSubmit, removeSearch}) => {
    return (
        <form className={styles['SearchInputForm']}
              onSubmit={(e) => onSubmit(e)}
        >
            <input
                className={styles['SearchInputForm__Input']}
                value={value}
                placeholder={i18n.t('SearchInputForm.placeholder')}
                id="search_input"
                onChange={(e) => onChange(e.target.value)}
            />
            <span className={styles['SearchInputForm__ButtonWrapper']}>
                {value !== '' && (
                    <button
                        type='button'
                        onClick={removeSearch}
                        className={styles['SearchInputForm__Reset']}
                        aria-label={i18n.t('SearchInputForm.reset')}
                    >
                        <Icon type={"close"} size={"small"} color={"black800"}/>
                    </button>
                )}
                <button
                    className={styles['SearchInputForm__Button']}
                    aria-label={i18n.t('SearchInputForm.search')}
                    type="submit"
                >
                    <span className={styles['SearchInputForm__ButtonText']}>
                        {i18n.t('SearchInputForm.search')}
                    </span>
                    <Icon type={'search'} size={'large'}/>
                </button>
            </span>
        </form>
    )
};

SearchInputForm.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    removeSearch: PropTypes.func
};

SearchInputForm.defaultProps = {
    value: "",
    onChange: () => {},
    onSubmit: () => {},
    removeSearch: () => {}
};

export default SearchInputForm;
